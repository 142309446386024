<template>
  <v-breadcrumbs :items="breadcrumbs" class="pa-0 mb-1 mx-2">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item v-if="loadingSelectedGroup || loadingUser" disabled>
        <v-progress-circular
          indeterminate
          color="primary"
          size="15"
          width="2"
        ></v-progress-circular>
      </v-breadcrumbs-item>
      <v-breadcrumbs-item
        v-else-if="item.key === 'user_name'"
        class="text-subtitle-2 font-weight-regular"
        :class="`${
          editUserShifts ? 'primary--text link' : ''
        } text-subtitle-2 font-weight-regular`"
        :exact="!editUserShifts"
        @click="setEditUserMode({ key: 'user_shifts', value: false })"
      >
        {{ selectedUserName }}
      </v-breadcrumbs-item>
      <v-breadcrumbs-item
        v-else-if="item.key === 'group_name'"
        :class="`${
          currentGroupEdit ? 'primary--text link' : ''
        } text-subtitle-2 font-weight-regular`"
        :exact="!currentGroupEdit"
        @click="currentGroupEdit ? removeEditMode() : null"
      >
        {{ selectedGroupName }}
      </v-breadcrumbs-item>
      <v-breadcrumbs-item
        v-else
        :to="item.to"
        class="text-subtitle-2 font-weight-regular"
        :disabled="item.disabled"
        exact
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      breadcrumbsToShow: [],
    };
  },
  watch: {
    currentLanguage() {
      this.breadcrumbs = this.setMyBreacrumb();
    },
    "$route.path"() {
      this.breadcrumbs = this.setMyBreacrumb();
    },
    loadingSelectedGroup() {
      this.breadcrumbs = this.setMyBreacrumb();
    },
    currentGroupEdit() {
      this.breadcrumbs = this.setMyBreacrumb();
    },
  },
  computed: {
    ...mapGetters([
      "currentLanguage",
      "selectedGroup",
      "loadingSelectedGroup",
      "editParticipants",
      "editRules",
      "currentGroupEdit",
      "selectedUserName",
      "loadingUser",
      "selectedGroupName",
      "editUserShifts",
    ]),

    breadcrumbs: {
      get() {
        return this.breadcrumbsToShow;
      },
      set(value) {
        this.breadcrumbsToShow = value;
      },
    },

    routeBreadcrumbs() {
      return this.$route.meta.breadcrumb;
    },
  },
  methods: {
    ...mapMutations(["removeEditMode", "setEditUserMode"]),

    setMyBreacrumb() {
      let temporaryBreadCrumb = [];
      let breadcrumbs = this.$route.meta.breadcrumb;

      breadcrumbs.forEach((pageToGo) => {
        let item = {};

        if (pageToGo.key === "get_group_name") {
          item.key = "group_name";
          item.text = this.selectedGroupName;
        } else if (pageToGo.key === "get_user_name") {
          item.key = "user_name";
        } else if (pageToGo.key === "get_group_current_edit") {
          if (this.currentGroupEdit) {
            item.text = this.$ml.get(this.currentGroupEdit[0]);
            item.key = "group_setting";
          }
        } else if (pageToGo.key == "get_user_current_edit") {
          if (this.editUserShifts) {
            item.text = this.$ml.get("groups");
            item.key = "user_shifts_setting";
          }
        } else {
          item.text = this.$ml.get(pageToGo.key);
        }

        if (pageToGo.path) {
          item.to = { name: pageToGo.path };
        } else {
          item.exact = true;
        }
        temporaryBreadCrumb.push(item);
      });

      if (!this.currentGroupEdit && this.$route.name === "Group") {
        temporaryBreadCrumb.pop();
      }

      if (!this.editUserShifts && this.$route.name === "User") {
        temporaryBreadCrumb.pop();
      }

      return temporaryBreadCrumb;
    },
  },
  beforeMount() {
    this.breadcrumbs = this.setMyBreacrumb();
  },
};
</script>
