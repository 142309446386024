var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.equalColumns)?_c('v-row',{class:("ma-0 " + _vm.getStyle + " rounded-t")},[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_vm._t("left")],2),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_vm._t("right")],2)],1):_c('v-row',{class:((_vm.$vuetify.breakpoint.xs
        ? 'd-flex flex-column'
        : 'd-flex align-center flex-wrap') + " ma-0 py-0 px-4 " + _vm.getStyle + " rounded-t")},[(_vm.isSelectMode && !_vm.customLeft)?_c('v-col',{class:"pa-0",attrs:{"cols":"12"}},[_c('v-row',{class:((_vm.$vuetify.breakpoint.xs
            ? 'flex-column justify-start'
            : 'align-center') + " " + (_vm.$vuetify.breakpoint.mobile ? 'mb-3' : 'pa-0 ma-0')),attrs:{"dense":"","no-gutters":""}},[_c('v-col',{class:"ma-0 pa-0",attrs:{"lg":"4","xl":"3","sm":"12","xs":"12"}},[_c('v-row',{staticClass:"align-center pa-0 mx-0",attrs:{"dense":"","no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"","small":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.lg},on:{"click":function($event){return _vm.$emit('reset_selection')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)]}}],null,false,2088226939)},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$ml.get("reset_selection")))])]),(_vm.hasTitle)?_c('v-card-title',{class:("ml-0 px-0 word-break " + (_vm.$vuetify.breakpoint.xs
                  ? 'text-subtitle-1 font-weight-medium'
                  : ''))},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1)],1),_c('v-col',{class:"ma-0 pa-0",attrs:{"lg":"8","xl":"9","sm":"12","xs":"12"}},[_vm._t("actions")],2)],1)],1):_vm._e(),(_vm.customLeft)?_c('v-col',{class:((_vm.$vuetify.breakpoint.xs ? '' : 'd-flex align-center') + " py-0 px-0"),attrs:{"xl":"9","lg":"8","md":"7","sm":"7","xs":"12"}},[_vm._t("left")],2):(!_vm.customLeft && !_vm.isSelectMode)?_c('v-col',{class:((_vm.$vuetify.breakpoint.xs ? '' : 'd-flex align-center') + " pa-0 my-0"),attrs:{"xl":_vm.hasActionButton ? 9 : 5,"lg":_vm.hasActionButton ? 8 : 5,"md":"7","sm":"7","xs":"12"}},[_c('v-row',{class:("pa-0 ma-0 " + (_vm.$vuetify.breakpoint.xs
            ? 'flex-column justify-start'
            : 'align-center')),attrs:{"dense":"","no-gutters":""}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"lg":"7","xl":"5","md":"8"}},[_c('v-row',{staticClass:"align-center pa-0 ma-0",attrs:{"dense":"","no-gutters":""}},[(_vm.hasTitle)?_c('v-card-title',{staticClass:"ma-0 px-2 word-break"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp && _vm.hasSubtitle)?_c('v-divider',{staticClass:"my-6 mx-1",attrs:{"vertical":""}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp && _vm.hasSubtitle)?_c('v-card-subtitle',{class:("\n                word-break\n                " + (_vm.$vuetify.theme.dark
                    ? 'grey--text text--lighten-5'
                    : 'grey--text text--darken-2') + "\n                text-body-1\n                " + (_vm.$vuetify.breakpoint.xs ? 'mx-0 px-2' : '') + "\n              ")},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()],1)],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{class:"ma-0 pa-0",attrs:{"xl":"7","lg":"5","md":"4"}},[_vm._t("actions")],2):_vm._e()],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xs)?_c('v-col',{staticClass:"d-flex align-center py-0 px-0",attrs:{"xs":"12"}}):_vm._e(),(_vm.customRight)?_c('v-col',{class:((_vm.$vuetify.breakpoint.xs ? 'py-4' : '') + " d-flex align-center py-0 px-0"),attrs:{"lg":"7","md":"5","sm":"5","xs":"12"}},[_vm._t("right")],2):(!_vm.hideActionButton)?_c('v-col',{class:((_vm.$vuetify.breakpoint.xs ? 'pt-0 pb-4' : '') + " d-flex align-center pa-0"),attrs:{"xl":_vm.hasActionButton ? 3 : 7,"lg":_vm.hasActionButton ? 4 : 7,"md":"5","sm":"5","xs":"12"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-btn',{class:"text-none text-body-1 font-weight-medium",attrs:{"large":"","elevation":"0","color":"accent","text":_vm.$vuetify.theme.dark,"outlined":_vm.$vuetify.theme.dark,"block":_vm.$vuetify.breakpoint.xs,"disabled":_vm.disabledAction || _vm.loadingAction},on:{"click":function($event){return _vm.$emit(_vm.action)}}},[(_vm.actionIcon)?_c('v-icon',{class:("" + (_vm.actionIcon === 'mdi-sync' && _vm.loadingAction ? 'icon-spinner' : '')),attrs:{"left":"","size":"20"},domProps:{"textContent":_vm._s(_vm.actionIcon)}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get(_vm.actionText)))])],1)],1):_vm._e()],1),(_vm.$vuetify.breakpoint.smAndDown && _vm.hasActionButton && !_vm.customRight)?_c('v-row',{staticClass:"px-4 pt-0 pb-4 ma-0"},[_vm._t("actions")],2):_vm._e(),(!_vm.hideDivider)?_c('v-divider'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }