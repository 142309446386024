<template>
  <div>
    <v-row v-if="equalColumns" :class="`ma-0 ${getStyle} rounded-t`">
      <v-col lg="6" sm="12"><slot name="left" /></v-col>
      <v-col lg="6" sm="12"><slot name="right" /></v-col>
    </v-row>
    <v-row
      v-else
      :class="`${
        $vuetify.breakpoint.xs
          ? 'd-flex flex-column'
          : 'd-flex align-center flex-wrap'
      } ma-0 py-0 px-4 ${getStyle} rounded-t`"
    >
      <v-col v-if="isSelectMode && !customLeft" cols="12" :class="`pa-0`">
        <v-row
          dense
          no-gutters
          :class="`${
            $vuetify.breakpoint.xs
              ? 'flex-column justify-start'
              : 'align-center'
          } ${$vuetify.breakpoint.mobile ? 'mb-3' : 'pa-0 ma-0'}`"
        >
          <v-col lg="4" xl="3" sm="12" xs="12" :class="`ma-0 pa-0`">
            <!-- ÁREA DO BOTÃO DE CANCELAR SELEÇÃO + TÍTULO -->
            <v-row dense no-gutters class="align-center pa-0 mx-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="$emit('reset_selection')"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4"
                    :small="$vuetify.breakpoint.xs || $vuetify.breakpoint.lg"
                  >
                    <v-icon v-text="'mdi-close'" />
                  </v-btn>
                </template>
                <span class="caption">{{ $ml.get("reset_selection") }}</span>
              </v-tooltip>
              <v-card-title
                v-if="hasTitle"
                :class="`ml-0 px-0 word-break ${
                  $vuetify.breakpoint.xs
                    ? 'text-subtitle-1 font-weight-medium'
                    : ''
                }`"
              >
                {{ title }}
              </v-card-title>
            </v-row>
          </v-col>
          <v-col lg="8" xl="9" sm="12" xs="12" :class="`ma-0 pa-0`">
            <!-- ÁREA DOS BOTÕES DE AÇÃO EM MASSA -->
            <slot
              name="actions"
              :class="`d-flex ${$vuetify.breakpoint.xs ? 'flex-column' : ''}`"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="customLeft"
        xl="9"
        lg="8"
        md="7"
        sm="7"
        xs="12"
        :class="`${
          $vuetify.breakpoint.xs ? '' : 'd-flex align-center'
        } py-0 px-0`"
      >
        <slot name="left" />
      </v-col>
      <v-col
        v-else-if="!customLeft && !isSelectMode"
        :xl="hasActionButton ? 9 : 5"
        :lg="hasActionButton ? 8 : 5"
        md="7"
        sm="7"
        xs="12"
        :class="`${
          $vuetify.breakpoint.xs ? '' : 'd-flex align-center'
        } pa-0 my-0`"
      >
        <v-row
          dense
          no-gutters
          :class="`pa-0 ma-0 ${
            $vuetify.breakpoint.xs
              ? 'flex-column justify-start'
              : 'align-center'
          }`"
        >
          <v-col lg="7" xl="5" md="8" class="ma-0 pa-0">
            <!-- ÁREA DO BOTÃO DE CANCELAR SELEÇÃO + TÍTULO -->
            <v-row dense no-gutters class="align-center pa-0 ma-0">
              <v-card-title v-if="hasTitle" class="ma-0 px-2 word-break">
                {{ title }}
              </v-card-title>
              <v-divider
                vertical
                class="my-6 mx-1"
                v-if="$vuetify.breakpoint.mdAndUp && hasSubtitle"
              />
              <v-card-subtitle
                v-if="$vuetify.breakpoint.mdAndUp && hasSubtitle"
                :class="`
                  word-break
                  ${
                    $vuetify.theme.dark
                      ? 'grey--text text--lighten-5'
                      : 'grey--text text--darken-2'
                  }
                  text-body-1
                  ${$vuetify.breakpoint.xs ? 'mx-0 px-2' : ''}
                `"
              >
                {{ subtitle }}
              </v-card-subtitle>
            </v-row>
          </v-col>
          <v-col
            v-if="!$vuetify.breakpoint.smAndDown"
            xl="7"
            lg="5"
            md="4"
            :class="`ma-0 pa-0`"
          >
            <!-- ÁREA DOS FILTROS -->
            <slot
              name="actions"
              :class="`d-flex ${$vuetify.breakpoint.xs ? 'flex-column' : ''}`"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        xs="12"
        class="d-flex align-center py-0 px-0"
        v-if="$vuetify.breakpoint.xs"
      />
      <v-col
        v-if="customRight"
        lg="7"
        md="5"
        sm="5"
        xs="12"
        :class="`${
          $vuetify.breakpoint.xs ? 'py-4' : ''
        } d-flex align-center py-0 px-0`"
      >
        <slot name="right" />
      </v-col>
      <v-col
        v-else-if="!hideActionButton"
        :xl="hasActionButton ? 3 : 7"
        :lg="hasActionButton ? 4 : 7"
        md="5"
        sm="5"
        xs="12"
        :class="`${
          $vuetify.breakpoint.xs ? 'pt-0 pb-4' : ''
        } d-flex align-center pa-0`"
      >
        <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
        <v-btn
          large
          elevation="0"
          color="accent"
          :text="$vuetify.theme.dark"
          :outlined="$vuetify.theme.dark"
          @click="$emit(action)"
          :block="$vuetify.breakpoint.xs"
          :class="`text-none text-body-1 font-weight-medium`"
          :disabled="disabledAction || loadingAction"
        >
          <v-icon
            v-if="actionIcon"
            v-text="actionIcon"
            left
            size="20"
            :class="`${
              actionIcon === 'mdi-sync' && loadingAction ? 'icon-spinner' : ''
            }`"
          />
          <span> {{ $ml.get(actionText) }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="$vuetify.breakpoint.smAndDown && hasActionButton && !customRight"
      class="px-4 pt-0 pb-4 ma-0"
    >
      <!-- ÁREA DOS FILTROS VERSÃO MOBILE-->
      <slot
        name="actions"
        :class="`d-flex ${$vuetify.breakpoint.xs ? 'flex-column' : ''}`"
      />
    </v-row>
    <v-divider v-if="!hideDivider" />
  </div>
</template>
<script>
export default {
  name: "TableHeader",
  props: {
    id: { type: String, required: true },
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    action: { type: String, default: "" },
    actionText: { type: String, default: "add" },
    actionIcon: { type: String, default: "mdi-plus" },
    loadingAction: { type: Boolean, default: false },
    disabledAction: { type: Boolean, default: false },
    customLeft: { type: Boolean, default: false },
    customRight: { type: Boolean, default: false },
    equalColumns: { type: Boolean, default: false },
    hideDivider: { type: Boolean, default: false },
    hideActionButton: { type: Boolean, default: false },
    styles: { type: String, default: "" },
    isSelectMode: { type: Boolean, default: false },
  },
  computed: {
    hasTitle() {
      return this.title !== "" && !!this.title;
    },
    hasSubtitle() {
      return this.subtitle !== "" && !!this.subtitle;
    },
    hasActionButton() {
      return this.action !== "";
    },
    getStyle() {
      if (this.isSelectMode) {
        return this.$vuetify.theme.dark ? "grey darken-2" : "orange lighten-5";
      }
      return this.styles;
    },
  },
};
</script>
