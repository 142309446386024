import Vue from "vue";
import { pt_BR } from "@/languages/pt_BR";
import { en } from "@/languages/en";
import { es } from "@/languages/es";

import { MLInstaller, MLCreate, MLanguage } from "vue-multilanguage";

Vue.use(MLInstaller);

export default new MLCreate({
  initial: "pt_BR",
  save: process.env.NODE_ENV === "production",
  languages: [
    new MLanguage("en").create(en),
    new MLanguage("pt_BR").create(pt_BR),
    new MLanguage("es").create(es),
  ],
});
