<template>
  <HeaderDialog
    :show="showDialog"
    color="primary"
    :title="title"
    :subtitle="subtitle"
    :loading="loading"
    @close="showDialog = false"
    @action="$emit('ok')"
    close-text="cancel"
  >
    <template v-slot:body>
      <Alert v-if="alert && alert.show" dense :type="alert.type">
        <span v-html="alert.message"></span>
      </Alert>
      <v-card-text class="ma-0 pa-0 text-body-1" v-html="messageToShow" />
      <v-card-text
        class="ma-0 px-0 pb-0 pt-4 text-body-1"
        v-if="!hideReversibleText && !unsavedChanges"
      >
        {{ $ml.get(reversible ? "action_can_undo" : "action_cannot_undo") }}
      </v-card-text>
    </template>
  </HeaderDialog>
</template>
<script>
export default {
  name: "ConfirmationDialog",
  props: {
    show: { type: Boolean, required: true },
    actionText: { type: String },
    target: { type: String, default: "" },
    unsavedChanges: { type: Boolean, default: false },
    reversible: { type: Boolean, default: true },
    hideReversibleText: { type: Boolean, default: false },
    message: { type: String, default: "" },
    alert: { type: [Object, Boolean], default: false },
    loading: { type: Boolean, default: false },
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    title() {
      return this.target ? this.target : this.actionText;
    },
    subtitle() {
      return this.target ? this.actionText : "";
    },
    messageToShow() {
      if (this.message) {
        return `${this.message}`;
      } else {
        if (this.unsavedChanges) {
          return this.$ml.get("leave_without_save_changes");
        } else {
          return `${this.$ml.get("are_you_sure")} <b>${
            (this.actionText && this.actionText.toLowerCase()) || ""
          }</b> ${this.$ml.get("confirm_this_action")}?`;
        }
      }
    },
  },
};
</script>
