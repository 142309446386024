// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// alternar configs com base na variável de ambiente
const firebaseConfigEnv = {
  development: {
    apiKey: "AIzaSyBV5O75mx63DBDzqzdqxSbADdOil5NxQ_E",
    authDomain: "dev-conecta-control.firebaseapp.com",
    databaseURL: "https://dev-conecta-control-default-rtdb.firebaseio.com",
    projectId: "dev-conecta-control",
    storageBucket: "dev-conecta-control.appspot.com",
    messagingSenderId: "454093711162",
    appId: "1:454093711162:web:8e9c3c34880990a69e5203",
    measurementId: "G-HN0161NHCQ",
  },
  production: {
    apiKey: "AIzaSyBWEtoRzARXsQVtQURlRvhZmoxc_0TtFZY",
    authDomain: "conecta-control.firebaseapp.com",
    databaseURL: "https://conecta-control-default-rtdb.firebaseio.com",
    projectId: "conecta-control",
    storageBucket: "conecta-control.appspot.com",
    messagingSenderId: "62495908921",
    appId: "1:62495908921:web:30ca39c1ac4592f74199e8",
    measurementId: "G-V3RYC98YCL",
  },
};

const environment = process.env.NODE_ENV;

const firebaseConfig = firebaseConfigEnv[environment];

const app = firebase.initializeApp(firebaseConfig);

// Initialize Cloud Storage and get a reference to the service
getStorage(app);
getAnalytics(app);
