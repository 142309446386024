<template>
  <v-app :class="getClass">
    <v-main>
      <router-view />
      <!-- SNACKBARS DE AVISO -->
      <v-snackbar
        v-if="snackbarMessageKey !== ''"
        v-model="showGlobalSnackbar"
        multi-line
        :color="snackbarColor"
        elevation="2"
      >
        <div v-if="snackbarIsAKey" class="font-weight-medium">
          {{ $ml.get(snackbarMessageKey) }}
        </div>
        <div v-else class="font-weight-medium">
          {{ snackbarMessageKey }}
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn
            :color="snackbarColor !== 'warning' ? 'white' : 'grey darken-2'"
            text
            v-bind="attrs"
            @click="resetSnackbarInfo()"
          >
            {{ $ml.get("close") }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  methods: {
    ...mapActions(["resetSnackbarInfo"]),
    ...mapMutations(["showOrHideSnackbar"]),
  },
  computed: {
    ...mapGetters([
      "snackbar",
      "showSnackbar",
      "snackbarMessageKey",
      "snackbarTimeout",
      "snackbarColor",
      "snackbarIsAKey",
    ]),
    showGlobalSnackbar: {
      get() {
        return this.showSnackbar;
      },
      set(new_value) {
        this.showOrHideSnackbar(new_value);
      },
    },
    getClass() {
      const path = this.$route.path;
      if (
        path === "/login" ||
        path === "/login/beta" ||
        path === "/auth/callback"
      ) {
        return "auth";
      }
      return "";
    },
  },
  watch: {
    showGlobalSnackbar() {
      if (!this.showGlobalSnackbar) {
        this.resetSnackbarInfo();
      }
    },
  },
};
</script>
<style scoped>
.auth {
  background: #517bbd;
}
</style>
