var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{class:("d-flex align-center justify-start rounded-pill " + _vm.trialBarClass + " py-2 " + (_vm.mobile ? 'px-0' : 'mx-2 px-3')),staticStyle:{"max-height":"40px !important"}},[(_vm.controlInTrial)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"overflow":"hidden"}},[_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('span',{staticClass:"d-flex align-center",on:{"mouseover":function($event){_vm.showPlanDetails = true}}},[_c('Logo',{attrs:{"product":"conecta_control","width":"38","height":"38","white":!_vm.mobile || _vm.$vuetify.theme.dark,"noMargins":"","customClass":"mr-2"}}),_c('div',{staticClass:"flex-column mx-2 my-1"},[_c('div',{staticClass:"caption font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(_vm.$ml.get("days_remaining"))+" ")]),_c('div',{staticClass:"caption my-0 py-0"},[_vm._v(" "+_vm._s(_vm.endTrial(_vm.controlPlan.end_trial))+" ")])])],1)])]):_vm._e()])]}}])},[(_vm.showPlanDetails && _vm.controlPlan)?_c('v-card',{staticClass:"d-flex flex-column justify-center elevation-0",attrs:{"width":"340"}},[_c('v-card-title',{staticClass:"py-2"},[_c('div',{staticClass:"d-flex justify-center align-center ma-2 py-0"},[_c('span',{staticClass:"font-weight-bold text-wrap"},[_vm._v(_vm._s(_vm.controlPlan.plan))]),_c('v-chip',{staticClass:"ml-4",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.controlPlan.status)+" ")])],1)]),_c('v-divider'),_c('div',{staticClass:"py-4 mx-3 d-flex flex-column"},[_c('v-card-text',{staticClass:"py-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$ml.get("days_remaining"))+":")]),(
            !_vm.daysToEndTrial(_vm.controlPlan.end_trial) &&
            _vm.checkExpiresToday(_vm.controlPlan.end_trial)
          )?_c('span',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(_vm.$ml.get("last_trial_day"))+" ")]):_c('span',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(_vm.daysToEndTrial(_vm.controlPlan.end_trial) || _vm.$ml.get("expired"))+" ")])]),_c('v-card-text',{staticClass:"py-1"},[(_vm.daysToEndTrial(_vm.controlPlan.end_trial))?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$ml.get("test_ends_in"))+":")]):(
            !_vm.daysToEndTrial(_vm.controlPlan.end_trial) &&
            _vm.checkExpiresToday(_vm.controlPlan.end_trial)
          )?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$ml.get("test_ends_today"))+":")]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$ml.get("test_ended_in"))+":")]),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.formatDateFullMonth(_vm.controlPlan.end_trial)))])]),_c('v-card-text',{staticClass:"py-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$ml.get("licenses_number"))+":")]),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.usersNumber)+" ")])]),_c('v-card-text',{staticClass:"py-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$ml.get("created_at"))+":")]),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.formatDateFullMonth(_vm.controlPlan.init_date)))])]),_c('v-card-text',{staticClass:"py-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$ml.get("products"))+":")]),_c('v-card-actions',{staticClass:"align-start mx-2 flex-wrap"},_vm._l((_vm.controlPlan.products),function(product){return _c('span',{key:product,staticClass:"mr-2",staticStyle:{"max-width":"40px"}},[_c('Logo',{attrs:{"products":"","product":product,"height":"30","no-margins":"","white":_vm.$vuetify.theme.dark}})],1)}),0),_c('v-btn',{staticClass:"mb-3 px-4 mt-5 mx-auto elevation-0 text-none text-body-2 font-weight-medium",attrs:{"color":"accent","dark":""},on:{"click":_vm.goToSelfCheckout}},[_vm._v(" "+_vm._s(_vm.$ml.get("request_activation"))+" ")]),_c('v-btn',{staticClass:"mx-auto mb-2 text-none caption font-weight-medium transparent",attrs:{"color":"grey darken-2","text":"","x-small":""},on:{"click":_vm.openCompanyProfile}},[_vm._v(" "+_vm._s(_vm.$ml.get("company_information"))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }