var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-3",attrs:{"fab":"","fixed":"","bottom":"","right":"","dark":"","color":"light-blue darken-4","elevation":"0"}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-face-agent')}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("help")))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-3"},[_c('v-card-title',{staticClass:"text-h5 primary--text mb-4"},[_vm._v(" "+_vm._s(_vm.$ml.get("help"))+" ")]),_c('v-list',{staticClass:"px-5"},_vm._l((_vm.options),function(ref,index){
var icon = ref.icon;
var title = ref.title;
var action = ref.action;
return _c('v-list-item',{key:index,staticClass:"py-2 grey lighten-4 secondary--text text--lighten-2 mb-4",attrs:{"link":""},on:{"click":function($event){return _vm.open(action)}}},[_c('v-list-item-action',{staticClass:"mr-5"},[_c('v-avatar',{attrs:{"color":"accent"}},[_c('v-icon',{attrs:{"size":"26","color":"white"},domProps:{"textContent":_vm._s(icon)}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap break-word normal-line-height font-weight-medium text-h6 mt-1",domProps:{"innerHTML":_vm._s(_vm.$ml.get(title))}})],1),_c('v-list-item-action',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-arrow-right')}})],1)],1)}),1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary lighten-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$ml.get("close"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }