<template>
  <v-app>
    <v-overlay
      :value="loading"
      opacity="0.7"
      z-index="200"
      :color="$vuetify.theme.dark ? 'secondary' : 'primary darken-5'"
    >
      <v-progress-circular
        width="6"
        size="100"
        :color="$vuetify.theme.dark ? 'primary' : 'white'"
        indeterminate
      >
        <Logo
          product="conecta_control"
          height="35"
          noMargins
          :white="!$vuetify.theme.dark"
        />
      </v-progress-circular>
    </v-overlay>
    <Navbar @changeSidebarStatus="openOrCloseSidebar" @openChat="initChat" />
    <Sidebar
      :show="showSidebar"
      :showPermanent="permanent"
      :showMini="mini"
      @changeSidebarStatus="showSidebar = !showSidebar"
      :mobile="isMobile"
    />

    <v-main :class="`${$vuetify.theme.dark ? 'background' : ''}`">
      <!-- AVISO DE RESELLER -->
      <v-banner
        v-if="currentUser.is_simulated"
        sticky
        app
        :single-line="!$vuetify.breakpoint.smAndDown"
        :light="!$vuetify.theme.dark"
        :dark="$vuetify.theme.dark"
        color="background"
        :class="`${!$vuetify.breakpoint.smAndDown ? 'py-1' : ''} ${
          mini ? 'px-0' : ''
        } reseller-banner`"
        icon="mdi-alert-circle-outline"
        icon-color="warning"
        style="z-index: 2"
      >
        {{ $ml.get("conecta_partners_simulation") }}
        <span class="font-weight-bold primary--text">{{
          currentUser.domain_name
        }}</span>
        <template v-slot:actions>
          <v-btn
            small
            width="100%"
            outlined
            rounded
            @click="exchangeTokenBack()"
            color="primary"
          >
            <v-icon left v-text="'mdi-arrow-left'" dense />{{
              $ml.get("previous")
            }}
          </v-btn>
        </template>
      </v-banner>

      <v-container fluid class="px-0">
        <v-row class="mx-2 d-flex flex-column mt-1 mb-3" v-if="hasBreadcrumbs">
          <Breadcrumb />
        </v-row>
        <v-container fluid class="px-4 py-0 my-0 mx-0">
          <UpgradeBanner v-if="!loading" />

          <Alert
            class="mx-0 mt-4 mb-4"
            type="info"
            :prominent="false"
            :btnWidth="$vuetify.breakpoint.smAndDown ? '100' : '20'"
            :alignBtnTextStart="$vuetify.breakpoint.smAndDown ? true : false"
            v-if="
              !isSsoActive && hasControlPlan && isNotActivationPage && !loading
            "
            dense
            @action="
              hasScheduleActivationInfo
                ? (showCancelScheduleActivationDialog = true)
                : $router.push({ name: 'ActivationSteps' })
            "
            :actionText="
              hasScheduleActivationInfo
                ? $ml.get('cancel_schedule_activation')
                : $ml.get('active_control')
            "
          >
            <span v-if="hasScheduleActivationInfo">
              {{ $ml.get("schedule_activation_alert_description") }}
              {{
                formatDateAndHour(scheduleActivationInfo.activation_datetime)
              }}
            </span>
            <span v-else>
              {{ $ml.get("disable_sso_warning") }}
            </span>
          </Alert>

          <!-- TODO: REMOVER AVISOS DO SSO ANTIGO APÓS 04/03/24 -->
          <Alert
            v-if="hasOldSSOActive"
            class="ma-0"
            outlined
            :show-action="false"
            type="warning"
            :prominent="!$vuetify.breakpoint.mobile"
            text-color="warning--text text--darken-4"
          >
            <h3 class="text-h5 mb-1">
              <strong>{{ $ml.get("old_sso_title_message") }}!</strong>
            </h3>

            {{ $ml.get("old_sso_title_message_2") }}

            <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters align="center">
              <v-col lg="9" sm="12" md="8" class="pa-0 grow">
                {{ $ml.get("old_sso_sub_message") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col lg="3" sm="12" md="4" class="pa-0 shrink d-flex">
                <v-btn
                  text
                  small
                  outlined
                  color="warning"
                  href="https://confluence.external-share.com/content/93815/novo_sistema_de_login_unico_"
                  target="_blank"
                  class="ml-auto text-body-2 font-weight-medium"
                >
                  {{ $ml.get("more_link_text") }}
                  <v-icon right v-text="'mdi-open-in-new'" dense />
                </v-btn>
              </v-col>
            </v-row>
          </Alert>

          <!-- SCOPE ALERT  -->
          <Alert
            v-if="missingScopesText && !loading"
            class="mt-4 mx-0 mb-4 mb-0"
            :show-action="false"
            type="warning"
            outlined
            :prominent="!$vuetify.breakpoint.mobile"
            text-color="warning--text text--darken-4"
          >
            <h3 class="text-h5">
              <strong>{{ $ml.get("attention") }}!</strong>
            </h3>
            <span v-html="$ml.get('error_missing_scope')"></span>
            <br />
            <span v-if="hasAdminPermission">
              {{ $ml.get("error_missing_scope_support") }}
            </span>
            <span v-else>
              {{ $ml.get("error_missing_scope_for_non_admin") }}
            </span>
            <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters align="center">
              <v-col lg="9" sm="12" md="8" class="pa-0 grow">
                <span v-html="$ml.get('error_missing_scope_alert')"></span>
              </v-col>
              <v-col
                lg="3"
                sm="12"
                md="4"
                class="pa-0 shrink d-flex justify-end"
              >
                <v-btn
                  text
                  small
                  color="secondary lighten-2"
                  href="https://conectasuite.com/perguntas-frequentes/como-permitir-acesso-a-novos-escopos-no-painel-do-google-administrativo-2/"
                  target="_blank"
                  class="mr-2 text-body-2 font-weight-medium"
                >
                  {{ $ml.get("more_link_text") }}
                </v-btn>
                <v-btn
                  v-if="hasAdminPermission"
                  text
                  small
                  outlined
                  color="warning"
                  :href="missingScopesText"
                  target="_blank"
                  class="ml-auto text-body-2 font-weight-medium"
                >
                  {{ $ml.get("accept_scopes") }}
                </v-btn>
              </v-col>
            </v-row>
          </Alert>
        </v-container>
        <router-view />
      </v-container>

      <!-- SNACKBARS DE AVISO -->
      <v-snackbar
        v-if="snackbarMessageKey !== ''"
        v-model="showGlobalSnackbar"
        multi-line
        :color="snackbarColor"
        elevation="2"
        :timeout="snackbarTimeout"
      >
        <div v-if="snackbarIsAKey" class="font-weight-medium">
          {{ $ml.get(snackbarMessageKey) }}
        </div>
        <div v-else class="font-weight-medium">
          {{ snackbarMessageKey }}
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="resetSnackbarInfo()">
            {{ $ml.get("close") }}
          </v-btn>
        </template>
      </v-snackbar>
      <ScheduleMeeting
        v-if="!loading && controlInTrialOrInexistent && !isSmallClient"
      />
      <ConfirmationDialog
        :target="$ml.get('cancel_schedule_activation_dialog_title')"
        :show="showCancelScheduleActivationDialog"
        @close="showCancelScheduleActivationDialog = false"
        @ok="cancelScheduleActivation"
        :reversible="true"
        :loading="cancelActivationLoading"
      />

      <HeaderDialog
        :show="attentionToPriceChange"
        id="new_product_price_editing"
        :title="$ml.get('priceAdjustment')"
        :subtitle="$ml.get('priceChangeSubtitle')"
        width="800"
        :showCloseBtn="false"
        :actionText="$ml.get('iUnderstood')"
        @action="priceChangeNoticed"
      >
        <template v-slot:body>
          <v-row class="mx-3 mb-2" no-gutters>
            <p class="mt-2">
              <span v-html="$ml.get('priceChangeWarning')"></span>
              <a
                href="https://conectasuite.com/precos/"
                target="_blank"
                class="text-body-1 font-weight-bold text-decoration-none"
              >
                <span class="blue--text text--darken-2"> Conecta Suite.</span>
              </a>
            </p>

            <p>{{ $ml.get("noActionNeeded") }}</p>

            <p>
              {{ $ml.get("willingToHelp") }}
              <a
                class="text-body-1 font-weight-bold text-decoration-none"
                href="mailto:comercial@conectasuite.com"
                ><span class="text-body-1 font-weight-bold">
                  comercial@conectasuite.com</span
                ></a
              >.
            </p>

            <p>{{ $ml.get("thanks4BeeingClient") }}</p>
          </v-row>
        </template>
      </HeaderDialog>
    </v-main>
  </v-app>
</template>
<script>
import * as moment from "moment";
import { getDatabase, ref, set, get, child } from "firebase/database";
import UpgradeBanner from "@/components/billing/upgrade/UpgradeBanner";
import Navbar from "@/components/main/Navbar";
import Sidebar from "@/components/main/Sidebar";
import Alert from "@/components/base/Alert";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Breadcrumb from "@/components/base/Breadcrumb";
import ScheduleMeeting from "@/components/base/ScheduleMeeting";
import {
  helpMoreLink,
  linkAdminGoogleApps,
  suportEmail,
} from "@/helpers/links";
import { formatDateAndHour } from "@/helpers/utils";
import {
  SUITE_DEV_PROJECT_NAME,
  SUITE_PROD_PROJECT_NAME,
} from "@/helpers/variables";

import { domainsToSeePriceChangeWarning } from "@/helpers/domainsSeePriceChange";

export default {
  components: {
    Navbar,
    Sidebar,
    Breadcrumb,
    Alert,
    ScheduleMeeting,
    UpgradeBanner,
  },
  data() {
    return {
      showSidebar: true,
      mini: false,
      permanent: true,
      showAlertControlPlan: false,
      helpMoreLink,
      linkAdminGoogleApps,
      suportEmail,
      showCancelScheduleActivationDialog: false,
      cancelActivationLoading: false,
      attentionToPriceChange: false,
      domainsToSeePriceChangeWarning,
    };
  },

  computed: {
    ...mapGetters([
      "loading",
      "snackbar",
      "showSnackbar",
      "snackbarMessageKey",
      "snackbarTimeout",
      "snackbarColor",
      "token",
      "currentLanguageCode",
      "snackbarIsAKey",
      "isSsoActive",
      "controlPlan",
      "planStatusAlert",
      "hasControlPlan",
      "currentUser",
      "missingScopesControl",
      "controlInTrialOrInexistent",
      "hasIntermediaryPartner",
      "companyInSuite",
      "isSimulation",
      "hasScheduleActivationInfo",
      "scheduleActivationInfo",
      "isSmallClient",
      //TODO: REMOVER AVISOS DO SSO ANTIGO APÓS 04/03/24
      "hasOldSSOActive",
      "hasAdminPermission",
      "mainDomain",
    ]),

    showGlobalSnackbar: {
      get() {
        return this.showSnackbar;
      },
      set(new_value) {
        this.showOrHideSnackbar(new_value);
      },
    },

    hasBreadcrumbs() {
      return this.$route.meta.breadcrumb ? true : false;
    },

    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },

    controlPlanInfo() {
      if (this.showAlertControlPlan) {
        if (this.planStatusAlert == "expiring_trial") {
          return moment(this.controlPlan.end_trial).format(
            this.$ml.get(this.planStatusAlert)
          );
        }
        return this.$ml.get(this.planStatusAlert);
      }
      return null;
    },

    isNotActivationPage() {
      return this.$route.name !== "ActivationSteps";
    },

    missingScopesText() {
      if (!this.missingScopesControl) {
        return `${linkAdminGoogleApps}${process.env.VUE_APP_MARKETPLACE_APP_ID}`;
      }
      return null;
    },

    feedbacksButtons() {
      return document.getElementsByClassName("feedback-button");
    },
  },

  watch: {
    "$route.path"() {
      this.removeEditMode();
      this.resetSnackbarInfo();
    },

    isMobile() {
      if (this.isMobile) {
        this.permanent = false;
        this.mini = false;
        this.showSidebar = false;
      }
    },

    currentLanguageCode() {
      const currentLanguage = {
        en: "en",
        pt_BR: "pt",
        es: "es",
      };
      this.$vuetify.lang.current = currentLanguage[this.currentLanguageCode];
    },

    showGlobalSnackbar() {
      if (!this.showGlobalSnackbar) {
        this.resetSnackbarInfo();
      }
    },

    feedbacksButtons(newValue, oldValue) {
      if (newValue.lenght !== oldValue.lenght) {
        this.removeFeedback();
        this.setFeedback();
      }
    },
  },

  methods: {
    ...mapActions([
      "getUsers",
      "getGroups",
      "resetSnackbarInfo",
      "getCompanySsoStatus",
      "getControlPlanStatus",
      "exchangeToken",
      "getScopes",
      "getCurrentCompany",
      "setSnackbarDefault",
      "getHubspotToken",
    ]),

    ...mapMutations([
      "showOrHideSnackbar",
      "removeEditMode",
      "setControlPlan",
      "setHasControlPlan",
      "setLoadingStatus",
      "setLoadingSelectedGroup",
      "setPlanStatusAlert",
      "setScheduleActivationInfo",
    ]),

    formatDateAndHour,

    async initChat() {
      await this.getHubspotToken();
    },

    priceChangeNoticed() {
      this.setUserNoticedPriceChange();
      this.attentionToPriceChange = false;

      this.saveNoticedPriceChange();
    },

    setUserNoticedPriceChange() {
      const database = getDatabase();

      set(ref(database, `noticed_price_change/${this.currentUser.key}`), {
        email: this.currentUser.email,
        date: moment().utc().locale("pt-br").format("DD/MM/YYYY HH:mm:ss"),
        id_google: this.currentUser.user_id,
      });
    },

    checkIfUserAlreadySeenThePriceChange() {
      const dbRef = ref(getDatabase());

      get(child(dbRef, `noticed_price_change/${this.currentUser.key}`))
        .then((snapshot) => {
          const userSeenPriceChaged = snapshot.exists()
            ? snapshot.val()
            : false;

          if (!userSeenPriceChaged) {
            this.attentionToPriceChange = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    saveNoticedPriceChange() {
      const url = `${process.env.VUE_APP_BIG_QUERY_API}/save-noticed-price-changes`;
      const project_name =
        process.env.NODE_ENV !== "production"
          ? SUITE_DEV_PROJECT_NAME
          : SUITE_PROD_PROJECT_NAME;

      const { name, user_id, email } = this.currentUser;

      const payload = {
        project_name,
        app: "Conecta Control",
        name,
        id_google: user_id,
        email,
        main_domain: this.mainDomain,
      };

      this.$axios
        .post(url, payload)
        .then(() => {})
        .catch((err) => {
          console.error("saveNoticedPriceChange()", err);
        })
        .finally(() => {});
    },

    loadJiraFeedbackIntegration() {
      var s = document.createElement("script");
      s.src =
        "https://conectanuvem.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=pt-BR&collectorId=12a150ca";
      document.body.appendChild(s);
    },

    exchangeTokenBack() {
      this.exchangeToken().then(() => {
        if (this.$router.currentRoute.name != "Home") {
          this.$router.push({ name: "Home" });
        }
        this.$router.go();
      });
    },

    openOrCloseSidebar() {
      if (this.isMobile) {
        this.showSidebar = !this.showSidebar;
      } else {
        if (this.mini) {
          this.mini = false;
        } else {
          this.mini = true;
        }
      }
    },

    async checkControlStatus() {
      await this.getControlPlanStatus()
        .then(({ data }) => {
          this.setHasControlPlan(true);
          if (data.status == "TRIAL") {
            this.setPlanStatusAlert("expiring_trial");
          }
          this.setControlPlan(data);
        })
        .catch((error) => {
          let response = error.response.data;
          this.setControlPlan(response.data);
          this.setHasControlPlan(false);
          this.setPlanStatusAlert(response.code.toLowerCase());
        })
        .finally(() => {
          if (this.planStatusAlert) {
            this.showAlertControlPlan = true;
          }
        });
    },

    setFeedback() {
      const _this = this;
      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: function (showCollectorDialog) {
          _this.feedbacksButtons.forEach((element) => {
            element.addEventListener("click", (e) => {
              e.preventDefault();
              showCollectorDialog();
            });
          });
        },
      };
    },

    removeFeedback() {
      const _this = this;
      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: function () {
          _this.feedbacksButtons.forEach((element) => {
            element.removeEventListener("click", false);
          });
        },
      };
    },

    cancelScheduleActivation() {
      this.cancelActivationLoading = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/sso/cancel-activation`;

      this.$axios
        .post(
          url,
          {},
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then(() => {
          this.setSnackbarDefault({
            message: "success_cancel_schedule_activation",
            color: "success",
            show: true,
          });

          this.setScheduleActivationInfo({});

          if (this.$route.name === "SuccessfulActivation") {
            this.$router.push({ name: "Home" });
          }
        })
        .catch(() => {
          this.setSnackbarDefault({
            message: "error_cancel_activation_async",
            color: "error",
            show: true,
          });
        })
        .finally(() => {
          this.showCancelScheduleActivationDialog = false;
          this.cancelActivationLoading = false;
        });
    },
  },

  async created() {
    this.setLoadingSelectedGroup(true);
    this.setLoadingStatus(true);
    this.getCompanySsoStatus();
    this.getCurrentCompany();
    await this.getGroups();
    this.getUsers();
    this.setLoadingStatus(false);
    this.setLoadingSelectedGroup(false);
    if (localStorage.getItem("darkMode")) {
      this.$vuetify.theme.dark = JSON.parse(localStorage.getItem("darkMode"));
    }
  },

  async beforeMount() {
    this.getScopes();
    await Promise.all([
      this.loadJiraFeedbackIntegration(),
      await this.checkControlStatus(),
    ]);

    if (this.isSimulation) {
      return;
    }

    if (this.domainsToSeePriceChangeWarning.includes(this.mainDomain)) {
      this.checkIfUserAlreadySeenThePriceChange();
    }
  },

  mounted() {
    this.setFeedback();
  },

  beforeDestroy() {
    this.removeFeedback();
  },
};
</script>

<style lang="scss" scoped>
.reseller--alert-position {
  z-index: 5;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .reseller--alert-position > span {
    display: block;
  }
}
</style>
