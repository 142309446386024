<template>
  <v-app>
    <v-container>
      <v-overlay
        :value="true"
        opacity="1"
        z-index="200"
        color="primary darken-5"
      >
        <v-progress-circular width="6" size="110" color="white" indeterminate>
          <Logo
            product="conecta_control"
            height="55"
            noMargins
            white
            class="blink"
          />
        </v-progress-circular>
        <div class="text-h6 font-weight-bold pt-6">
          {{ $ml.get("loading") }}
        </div>
      </v-overlay>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "PageLoading",
};
</script>
<style lang="scss" scoped>
.icon-spinner {
  animation: spin-animation 1.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
