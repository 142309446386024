import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/es5/locale/pt";
import en from "vuetify/es5/locale/en";
import es from "vuetify/es5/locale/es";
import store from "../store";

Vue.use(Vuetify);

const currentLanguage = {
  en: "en",
  pt_BR: "pt",
  es: "es",
};

export default new Vuetify({
  lang: {
    locales: { pt, en, es },
    current: currentLanguage[store.getters.currentLanguageCode],
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#517BBD",
        accent: "#FABB09",
        background: "#fff",
        background_hover: "#F6F6F6",
        text: "#424242",
      },
      dark: {
        primary: "#517BBD",
        accent: "#FABB09",
        background: "#202124",
        background_hover: "#F6F6F6",
        text: "#E0E0E0",
      },
    },
  },
});
