import axios from "axios";

const srcHubspotDev = "//js.hs-scripts.com/43532016.js";
const srcHubspotProd = "//js.hs-scripts.com/40115374.js";

function getSrcByEnv() {
  if (process.env.NODE_ENV === "production") {
    return srcHubspotProd;
  }
  return srcHubspotDev;
}

export function setPathDefaultHubSpotTrack() {
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(["setPath", "/"]);
  _hsq.push(["trackPageView"]);
}

export function loadHubSpotTracking() {
  var srcHubspot = getSrcByEnv();
  var s = document.createElement("script");
  s.src = srcHubspot;
  s.type = "text/javascript";
  s.id = "hs-script-loader";
  s.async = true;
  s.defer = true;

  window.hsConversationsSettings = {
    loadImmediately: false,
  };

  document.body.appendChild(s);
}

export function identifyUserOnHubSpot(currentUser, leadStatus = "OPEN") {
  var _hsq = (window._hsq = window._hsq || []);
  const { email, domain_name, name, username } = currentUser;

  let firstName = "";
  let lastName = "";

  if (name) {
    const splitedName = name.split(" ");
    firstName = splitedName[0];
    lastName = splitedName[splitedName.length - 1];
  }

  const user_data = {
    email,
    firstName: firstName || username,
    lastName: lastName || username,
    company: domain_name,
    hs_lead_status: leadStatus,
  };

  _hsq.push(["identify", user_data]);
}

export function reRenderHubSpotChat() {
  window.HubSpotConversations.widget.refresh();
}

export function loadHubSpotChat() {
  window.HubSpotConversations.widget.load();
}

export function removeHubSpotChat() {
  window.HubSpotConversations.widget.remove();
}

function setChatParams(isSalesChat) {
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);

  if (isSalesChat) {
    searchParams.set("sales_chat", "true");
    searchParams.delete("success_chat");
  } else {
    searchParams.set("success_chat", "true");
    searchParams.delete("sales_chat");
  }

  window.history.pushState(
    {},
    "",
    `${currentUrl.pathname}?${searchParams.toString()}`
  );
}

function onConversationsAPIReady(email, token, isSalesChat) {
  window.hsConversationsSettings = {
    identificationEmail: email,
    identificationToken: token,
  };
  setChatParams(isSalesChat);

  window.HubSpotConversations.widget.load();
  window.HubSpotConversations.widget.refresh();
}

export function registerUserToHubspotChat(email, hubToken, isSalesChat) {
  if (window.HubSpotConversations) {
    onConversationsAPIReady(email, hubToken, isSalesChat);
  } else {
    window.hsConversationsOnReady = [
      () => {
        onConversationsAPIReady(email, hubToken, isSalesChat);
      },
    ];
  }
}

export function submitHubspotForm(payload) {
  const url = `${process.env.VUE_APP_HUBSPOT_SERVICE_API}/hubspot/submit-form`;
  axios
    .post(url, payload)
    .then(() => {
      return false;
    })
    .catch((error) => {
      console.error(error);
    });
}
