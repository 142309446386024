import store from "@/store";

export async function openSuperLogicaClientArea() {
  let url =
    "https://brlgestao.superlogica.net/clients/areadocliente/clientes/formadepagamento";

  window.open(url, "_blank");
  store.dispatch("selfCheckoutSendSuperLogicaEmailPayment");
}

import { ACTIVE, TRIAL } from "@/helpers/backendConstants";

export const subscriptionPlans = {
  TRIAL: {
    ticketExpiration: 8, //  data final de trial + 8 dias
    status: TRIAL,
  },
  FLEX: {
    ticketExpiration: 10, // todo dia 10 de cada mês
    status: ACTIVE,
  },
  ANNUAL: {
    ticketExpiration: 7, //  depois de 7 dias da emissão do boleto
    status: ACTIVE,
  },
};
