import Vue from "vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "@/plugins/vue-tour";
import "@/plugins/firebase";
import "@/plugins/ml";
import Logo from "@/components/base/Logo";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import Alert from "@/components/base/Alert";
import SimpleDialog from "@/components/base/SimpleDialog";
import HeaderDialog from "@/components/base/HeaderDialog";
import TableHeader from "@/components/base/TableHeader";
import ConfirmationDialog from "@/components/general/ConfirmationDialog";
import PlayVideoButton from "@/components/base/PlayVideoButton";
import InternationalPhone from "@/components/general/InternationalPhone";
import { BoundingBox, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import App from "@/App.vue";

// optionally import default styles
import "./plugins/axios";

Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.component("Logo", Logo);
Vue.component("Dialog", SimpleDialog);
Vue.component("HeaderDialog", HeaderDialog);
Vue.component("TableHeader", TableHeader);
Vue.component("Alert", Alert);
Vue.component("ConfirmationDialog", ConfirmationDialog);
Vue.component("v-play-video-button", PlayVideoButton);
Vue.component("InternationalPhone", InternationalPhone);
Vue.component("cropper", Cropper);
Vue.component("bounding-box", BoundingBox);
require("dotenv").config();

/* Vue.prototype.$axios = _axios; */
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
