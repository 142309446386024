import axios from "axios";

const suiteAPIClient = axios.create({
  baseURL: process.env.VUE_APP_API_SUITE_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let token = localStorage.getItem("token");

suiteAPIClient.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default suiteAPIClient;
