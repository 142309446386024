<template>
  <v-menu offset-y open-on-hover close-delay="100">
    <template v-slot:activator="{ on, attrs }">
      <div
        :class="`d-flex align-center justify-start rounded-pill ${trialBarClass} py-2 ${
          mobile ? 'px-0' : 'mx-2 px-3'
        }`"
        style="max-height: 40px !important"
      >
        <!-- VERSÃO MOBILE -->
        <div
          v-if="controlInTrial"
          class="d-flex align-center"
          style="overflow: hidden"
        >
          <!-- ÁREA DO PLANO -->
          <div v-bind="attrs" v-on="on" class="d-flex align-center">
            <span
              @mouseover="showPlanDetails = true"
              class="d-flex align-center"
            >
              <Logo
                product="conecta_control"
                width="38"
                height="38"
                :white="!mobile || $vuetify.theme.dark"
                noMargins
                customClass="mr-2"
              />
              <div class="flex-column mx-2 my-1">
                <div class="caption font-weight-medium my-0 py-0">
                  {{ $ml.get("days_remaining") }}
                </div>
                <div class="caption my-0 py-0">
                  {{ endTrial(controlPlan.end_trial) }}
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </template>
    <!-- Exibição dos detalhes do plano -->
    <v-card
      v-if="showPlanDetails && controlPlan"
      class="d-flex flex-column justify-center elevation-0"
      width="340"
    >
      <v-card-title class="py-2">
        <div class="d-flex justify-center align-center ma-2 py-0">
          <span class="font-weight-bold text-wrap">{{ controlPlan.plan }}</span>
          <v-chip class="ml-4" x-small>
            {{ controlPlan.status }}
          </v-chip>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <div class="py-4 mx-3 d-flex flex-column">
        <v-card-text class="py-1">
          <span class="font-weight-bold">{{ $ml.get("days_remaining") }}:</span>
          <span
            class="pl-3"
            v-if="
              !daysToEndTrial(controlPlan.end_trial) &&
              checkExpiresToday(controlPlan.end_trial)
            "
          >
            {{ $ml.get("last_trial_day") }}
          </span>
          <span class="pl-3" v-else>
            {{ daysToEndTrial(controlPlan.end_trial) || $ml.get("expired") }}
          </span>
        </v-card-text>
        <v-card-text class="py-1">
          <span
            v-if="daysToEndTrial(controlPlan.end_trial)"
            class="font-weight-bold"
            >{{ $ml.get("test_ends_in") }}:</span
          >
          <span
            v-else-if="
              !daysToEndTrial(controlPlan.end_trial) &&
              checkExpiresToday(controlPlan.end_trial)
            "
            class="font-weight-bold"
            >{{ $ml.get("test_ends_today") }}:</span
          >
          <span v-else class="font-weight-bold"
            >{{ $ml.get("test_ended_in") }}:</span
          >
          <span class="pl-3">{{
            formatDateFullMonth(controlPlan.end_trial)
          }}</span>
        </v-card-text>
        <v-card-text class="py-1">
          <span class="font-weight-bold"
            >{{ $ml.get("licenses_number") }}:</span
          >
          <span class="pl-3">{{ usersNumber }} </span>
        </v-card-text>
        <v-card-text class="py-1">
          <span class="font-weight-bold">{{ $ml.get("created_at") }}:</span>
          <span class="pl-3">{{
            formatDateFullMonth(controlPlan.init_date)
          }}</span>
        </v-card-text>
        <v-card-text class="py-1 font-weight-bold"
          >{{ $ml.get("products") }}:</v-card-text
        >
        <v-card-actions class="align-start mx-2 flex-wrap">
          <span
            v-for="product in controlPlan.products"
            :key="product"
            style="max-width: 40px"
            class="mr-2"
          >
            <Logo
              products
              :product="product"
              height="30"
              no-margins
              :white="$vuetify.theme.dark"
            />
          </span>
        </v-card-actions>
        <v-btn
          @click="goToSelfCheckout"
          class="mb-3 px-4 mt-5 mx-auto elevation-0 text-none text-body-2 font-weight-medium"
          color="accent"
          dark
        >
          {{ $ml.get("request_activation") }}
        </v-btn>
        <v-btn
          class="mx-auto mb-2 text-none caption font-weight-medium transparent"
          color="grey darken-2"
          text
          x-small
          @click="openCompanyProfile"
        >
          {{ $ml.get("company_information") }}
        </v-btn>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatDateFullMonth, checkExpiresToday } from "@/helpers/utils";
import Logo from "@/components/base/Logo";
import { companyProfileLink } from "@/helpers/links";
import * as moment from "moment";

export default {
  name: "TrialBar",
  components: { Logo },
  props: {
    show: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
  },
  data() {
    return {
      showPlanDetails: false,
    };
  },

  computed: {
    ...mapGetters([
      "controlInTrial",
      "usersNumber",
      "controlPlan",
      "isMicrosoft",
    ]),

    trialBarClass() {
      if (this.show) {
        if (this.mobile) {
          return "bordered";
        }
        return "trial-products";
      }
      return "";
    },
  },

  methods: {
    ...mapActions(["selfcheckoutRedirect"]),

    formatDateFullMonth,

    checkExpiresToday,

    goToSelfCheckout() {
      if (this.isMicrosoft) {
        this.$router.push({ path: "/billing/upgrade" });
      } else {
        this.selfcheckoutRedirect();
      }
    },

    openCompanyProfile() {
      window.open(companyProfileLink, "_blank");
    },

    daysToEndTrial(day) {
      moment.locale("pt-br");
      const dateEndTrial = moment(day);
      return dateEndTrial.diff(moment().startOf("day"), "days");
    },

    endTrial(time) {
      moment.locale("pt-br");
      const dateEndTrial = moment(time);
      const diffDate = dateEndTrial.diff(moment().startOf("day"), "days");

      if (dateEndTrial.isAfter(moment(), "day")) {
        return `${
          diffDate === 1
            ? `${diffDate} ${this.$ml.get("remaining_day")}`
            : `${diffDate} ${this.$ml.get("days_remaining")}`
        } `;
      } else if (dateEndTrial.isSame(moment(), "day")) {
        return this.$ml.get("last_trial_day");
      } else {
        return this.$ml.get("expired");
      }
    },
  },
};
</script>
<style scoped>
.trial-products {
  border: 1px solid white;
}
</style>
