<template>
  <v-dialog
    :id="id"
    v-model="show"
    :width="width"
    :persistent="persistent"
    @keydown.esc="close()"
    @click:outside="close()"
  >
    <v-card class="pb-0">
      <v-card-title
        :class="`primary--text  ${buttonClass} d-flex pb-0 pl-4 pr-4 ${
          hasSubtitle ? 'mb-0' : 'mb-6'
        }`"
      >
        <span>{{ title }}</span>
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon v-text="'mdi-close'" color="secondary" />
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="hasSubtitle" class="mt-1" v-html="description" />
      <v-card-text class="text-md-body-1 px-4 mt-3">
        <slot name="body" />
      </v-card-text>
      <v-card-actions class="px-4" v-if="customFooter">
        <slot name="footer" />
      </v-card-actions>

      <v-card-actions class="pb-4 text-right" v-else-if="!readOnly">
        <v-spacer></v-spacer>
        <v-btn
          v-if="showCancel"
          :class="buttonClass"
          color="grey"
          class="text-none text-subtitle-1 font-weight-medium"
          text
          :disabled="loading"
          @click="close()"
        >
          {{ $ml.get("cancel") }}
        </v-btn>
        <v-btn
          :class="buttonClass"
          color="primary"
          :loading="loading"
          class="text-none elevation-0 text-subtitle-1 font-weight-medium"
          :disabled="disabledAction"
          @click="confirmAction()"
          text
        >
          {{ actionText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "SimpleDialog",
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String, default: "" },
    width: { type: String, default: "500" },
    persistent: { type: Boolean, default: false },
    show: { type: Boolean, required: true },
    customFooter: { type: Boolean, default: true },
    actionText: { type: String, default: "Confirmar" },
    buttonClass: { type: String, default: "" },
    disabledAction: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    showCancel: { type: Boolean, default: false },
  },
  computed: {
    hasSubtitle() {
      return this.description !== "";
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmAction() {
      this.$emit("action");
    },
  },
};
</script>
