<template>
  <v-app :class="getClass">
    <v-scale-transition>
      <v-main class="primary">
        <v-container fluid class="fill-height">
          <router-view />
          <!-- SNACKBARS DE AVISO -->
          <Snackbar />

          <HelpDialog v-if="!controlInTrial" />
        </v-container>
      </v-main>
    </v-scale-transition>
  </v-app>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Snackbar from "@/components/general/Snackbar.vue";
import HelpDialog from "@/components/general/HelpDialog.vue";

export default {
  name: "Activation",

  components: { Snackbar, HelpDialog },

  computed: {
    ...mapGetters([
      "company",
      "passwordStrength",
      "isSimulation",
      "controlInTrialOrInexistent",
      "currentUser",
      "hasIntermediaryPartner",
      "controlInTrial",
    ]),

    getClass() {
      const path = this.$route.path;
      if (
        path === "/login" ||
        path === "/login/beta" ||
        path === "/auth/callback"
      ) {
        return "auth";
      }
      return "";
    },
  },

  watch: {
    showGlobalSnackbar() {
      if (!this.showGlobalSnackbar) {
        this.resetSnackbarInfo();
      }
    },
  },

  methods: {
    ...mapActions([
      "getCurrentCompany",
      "getCompanySsoStatus",
      "resetSnackbarInfo",
    ]),

    ...mapMutations(["setLoadingStatus"]),
  },

  async beforeMount() {
    if (!this.company.main_domain) {
      this.setLoadingStatus(true);

      Promise.all([this.getCurrentCompany(), this.getCompanySsoStatus()]);

      this.setLoadingStatus(false);
    }
  },
};
</script>
<style scoped>
.auth {
  background: #517bbd;
}
</style>
