export const domainsToSeePriceChangeWarning = [
  "yangzi.com.br",
  "ayumi.com.br",
  "urximagem.com.br",
  "unityagro.com.br",
  "u3urbanismo.com.br",
  "tellus.org.br",
  "targetlaw.com.br",
  "superfix.ind.br",
  "sstocupacional.com.br",
  "spacere.com.br",
  "sintrabor.org.br",
  "simgular.com.br",
  "siembra.com.br",
  "sgmtecnologia.com",
  "sementesbarreirao.com.br",
  "sdvadvogados.com.br",
  "sdamed.com",
  "ryme.lt",
  "rekk.com.br",
  "qualic.com.br",
  "protecprotecoes.com.br",
  "proamar.com.br",
  "popovtransportes.com.br",
  "oto.com.br",
  "nexti.com",
  "nefadv.com.br",
  "multivac.com.br",
  "multipine.com",
  "mschmidtcontabil.com.br",
  "mowbrazil.com",
  "mjpcontroller.com.br",
  "milvus.com.br",
  "maclogistic.com",
  "lvalenca.com.br",
  "loviz.com.vc",
  "locusmed.com.br",
  "leandrofialhoadvogados.com.br",
  "lbin.com.br",
  "knowgap.com.br",
  "ideauto.com.br",
  "holdingaqbank.onmicrosoft.com",
  "hengelequipamentos.com.br",
  "hartmann.com.br",
  "gearseo.com.br",
  "foursales-company.com",
  "focosmais.com.br",
  "focomedicina.com",
  "fibernetwork.com.br",
  "efizi.com.br",
  "eduardoadvocacia.com",
  "dlsa.com.br",
  "digitalfavela.com.br",
  "dedetizadorasantana.com.br",
  "cursinoadvogados.adv.br",
  "ctsadv.com.br",
  "corpvs.com.br",
  "contabme.com.br",
  "construtorack.com.br",
  "brascabos.com.br",
  "bomcontrole.com.br",
  "barbosatranm.com.br",
  "bancopaulista.com.br",
  "atlglobalshipping.com",
  "allpartsnet.com.br",
  "aliar.org.br",
  "agimmigration.law",
  "agenciacosmica.com.br",
  "advogardigital.com",
  "acocon.com.br",
  "aafbb.org.br",
  "dev.conectanuvem.com.br",
];
