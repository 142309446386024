<template>
  <div
    v-if="mobileBreakpoint && $vuetify.breakpoint.mdAndDown"
    style="max-width: 200px"
  >
    {{ productsNames }}
  </div>
  <div
    v-else
    :class="`d-flex ma-0 pa-0 ${
      vertical ? 'flex-column justify-start' : 'align-start'
    }`"
  >
    <div
      v-for="product in products[slug]"
      :key="product"
      :style="`width: ${width}px; height: ${height}px; ${customStyle}`"
      :class="`${vertical ? 'py-1' : ''} ${customClass}`"
    >
      <Logo
        :product="product"
        :width="width"
        :height="height"
        :white="white"
        no-margins
        products
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable indent */
import { productNames } from "@/helpers/translateString";

export default {
  name: "ProductPlanIcons",

  props: {
    planSlug: { type: String, default: "enterprise" },
    width: { type: [String, Number], default: "40" },
    height: { type: [String, Number], default: "25" },
    white: { type: Boolean, default: false },
    customClass: { type: String, default: "" },
    customStyle: { type: String, default: "" },
    vertical: { type: Boolean, default: false },
    mobileBreakpoint: { type: Boolean, default: false },
    hideProducts: { type: Boolean, default: false },
  },

  data() {
    return {
      productNames,
      products: {
        conecta_suite: ["conecta_suite"],
        conecta_control: ["conecta_control"],
        conecta_sign: ["conecta_sign"],
        conecta_track: ["conecta_track"],
        sign_automation: ["sign_automation"],
        basic: ["conecta_suite"],
        standard: ["conecta_suite", "conecta_sign"],
        security: ["conecta_suite", "conecta_sign", "conecta_control"],
        enterprise: [
          "conecta_suite",
          "conecta_sign",
          "conecta_control",
          "conecta_control_mobile",
          "conecta_ad_sync",
          "integracao_ponto_mais",
        ],
        conecta_partner: [
          "conecta_suite",
          "conecta_sign",
          "conecta_control",
          "conecta_control_mobile",
          "conecta_ad_sync",
          "integracao_ponto_mais",
        ],
      },
    };
  },

  computed: {
    productsNames() {
      const products = this.products[this.planSlug];
      return products
        ? products
            .reduce((acc, product) => {
              if (productNames[product]) {
                acc.push(productNames[product]);
              }
              return acc;
            }, [])
            .join(", ")
        : [];
    },

    slug() {
      if (
        ["enterprise", "conecta_partner"].includes(this.planSlug) &&
        this.hideProducts
      ) {
        return "security";
      }
      return this.planSlug;
    },
  },
};
</script>
