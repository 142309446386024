<template>
  <v-dialog
    :id="id"
    scrollable
    v-model="show"
    :width="width"
    :persistent="persistent"
    @keydown.esc="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card flat tile>
      <v-toolbar
        flat
        class="mb-6 py-2"
        :color="color"
        dark
        min-height="78"
        max-height="150"
      >
        <v-toolbar-title class="font-weight-medium">
          <div>{{ title }}</div>

          <div class="text-sm-body-2">{{ subtitle }}</div>
        </v-toolbar-title>
        <v-spacer />
        <v-btn v-if="showCloseBtn" icon @click="$emit('close')">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-toolbar>

      <v-card-text class="text-md-body-1 mt-0">
        <slot name="body" />
      </v-card-text>

      <v-card-actions class="my-2">
        <slot name="footer-left" />

        <v-spacer />

        <slot v-if="customFooter" name="footer" />

        <v-btn
          v-else-if="showCloseBtn"
          color="grey darken-1"
          :disabled="loading"
          @click="$emit('close')"
          text
          >{{ $ml.get(closeText) }}</v-btn
        >

        <v-btn
          v-if="!readOnly"
          :color="color"
          text
          class="px-2 elevation-0 subtitle-1 font-weight-medium"
          :disabled="disabledAction"
          :loading="loading"
          @click="$emit('action')"
        >
          {{ actionText || $ml.get("confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "HeaderDialog",
  props: {
    id: { type: String, required: false },
    show: { type: Boolean, required: true },
    title: { type: String, required: true },
    subtitle: { type: String, default: "" },
    color: { type: String, default: "primary" },
    width: { type: String, default: "500" },
    persistent: { type: Boolean, default: true },
    customFooter: { type: Boolean, default: false },
    actionText: { type: String, default: "" },
    disabledAction: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    closeText: { type: String, default: "close" },
    readOnly: { type: Boolean, default: false },
    showCloseBtn: { type: Boolean, default: true },
  },
};
</script>
