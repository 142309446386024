<template>
  <v-tooltip v-if="products" top>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        avatar
        bordered
        overlap
        v-if="hasSubProductsOrServices"
        bottom
        color="grey darken-1"
        offset-x="20"
        offset-y="13"
      >
        <template v-slot:badge>
          <v-icon
            v-if="isConectaControlMobile"
            v-text="'mdi-cellphone-key'"
            color="white"
            class="pt-1"
            size="12"
          />
          <span
            v-if="isConectaControlAD"
            class="d-flex justify-center align-center conecta-ad"
          >
            AD
          </span>
          <span
            v-if="isPontoMais"
            class="d-flex justify-center align-center conecta-ad"
          >
            PM
          </span>
        </template>
        <v-img
          v-bind="attrs"
          v-on="on"
          contain
          :src="src"
          :width="width || height"
          :height="height"
          :class="`${customClass}`"
        ></v-img>
      </v-badge>
      <v-img
        v-else
        v-bind="attrs"
        v-on="on"
        contain
        :src="src"
        :width="width"
        :height="height"
        :class="`${customClass}`"
      ></v-img>
    </template>
    <span>{{ $ml.get(product) }}</span>
  </v-tooltip>
  <a v-else-if="link" :href="getProductLink" target="_blank">
    <v-img
      contain
      :src="src"
      :width="width"
      :height="height"
      :href="link ? getProductLink : ''"
      :class="`${
        grey ? 'greyimg' : ''
      } mt-${margin} mb-${margin} ml-${margin} mr-${margin} ${customClass} link`"
    ></v-img>
  </a>
  <v-img
    v-else
    contain
    :src="src"
    :width="width"
    :height="height"
    :class="`${grey ? 'greyimg' : ''} ma-${margin} ${customClass}`"
  ></v-img>
</template>
<script>
import { conectaSignLink, suiteLink } from "@/helpers/links";

export default {
  props: {
    product: { type: String, required: true },
    width: { type: [String, Number], default: "" },
    height: { type: [String, Number], default: "65" },
    white: { type: Boolean, default: false },
    grey: { type: Boolean, default: false },
    full: { type: Boolean, default: false },
    noMargins: { type: Boolean, default: false },
    customClass: { type: String, default: "" },
    products: { type: Boolean, default: false },
    link: { type: Boolean, default: false },
  },
  data() {
    return {
      logos_icons: {
        conecta_suite: require("@/assets/appslogos/icons/icon-suite.png"),
        conecta_track: require("@/assets/appslogos/icons/icon-track.png"),
        conecta_sign: require("@/assets/appslogos/icons/icon-sign.png"),
        conecta_control: require("@/assets/appslogos/icons/icon-control.png"),
        conecta_support: require("@/assets/appslogos/icons/icon-support.png"),
        reseller: require("@/assets/appslogos/icons/icon-suite.png"),
        plans: require("@/assets/appslogos/icons/iconPlans.png"),
      },
      logos_icons_white: {
        conecta_suite: require("@/assets/appslogos/icons/IconSuiteBranco.png"),
        conecta_track: require("@/assets/appslogos/icons/IconTrackBranco.png"),
        conecta_sign: require("@/assets/appslogos/icons/IconSignBranco.png"),
        conecta_control: require("@/assets/appslogos/icons/IconControlBranco.png"),
        conecta_support: require("@/assets/appslogos/icons/IconSupportBranco.png"),
        reseller: require("@/assets/appslogos/icons/IconSuiteBranco.png"),
        plans: require("@/assets/appslogos/icons/IconPlansBranco.png"),
      },
      logos_full: {
        conecta_control: require("@/assets/appslogos/full/conecta_control_full.png"),
        conecta_sign: require("@/assets/appslogos/full/conecta_sign_full.png"),
        conecta_suite: require("@/assets/appslogos/full/conecta_suite_full.png"),
      },
      logos_full_white: {
        conecta_control: require("@/assets/appslogos/full/ConectaControlBrancoFull.png"),
        conecta_suite: require("@/assets/appslogos/full/ConectaSuiteBrancoFull.png"),
        conecta_control_without_icon: require("@/assets/appslogos/full/conecta_control_without_icon.png"),
      },
      conectaSignLink,
      suiteLink,
    };
  },
  computed: {
    hasSubProductsOrServices() {
      return [
        "conecta_control_mobile",
        "conecta_ad_sync",
        "integracao_ponto_mais",
        "sign_automation",
      ].includes(this.product);
    },
    isConectaControlMobile() {
      return this.product === "conecta_control_mobile";
    },
    isConectaControlAD() {
      return this.product === "conecta_ad_sync";
    },
    isPontoMais() {
      return this.product === "integracao_ponto_mais";
    },

    margin() {
      if (!this.noMargins) {
        let height = 0;
        if (this.height) {
          if (typeof this.height === "string") {
            height = this.height;
            height.replace("px", "");
          }
          height = Math.trunc(parseInt(height) / 12);
          return height;
        }
        let logo = new Image();
        logo.src = this.src;
        height = Math.trunc(logo.height / 12);
        return height <= 6 ? height : 16;
      } else {
        return 0;
      }
    },
    src() {
      if (this.full) {
        if (this.white) {
          return Object.keys(this.logos_full_white).includes(this.product)
            ? this.logos_full_white[this.product]
            : this.logos_icons_white["plans"];
        }
        return Object.keys(this.logos_full).includes(this.product)
          ? this.logos_full[this.product]
          : this.logos_full["plans"];
      } else if (this.white && !this.full) {
        return Object.keys(this.logos_icons_white).includes(this.product)
          ? this.logos_icons_white[this.product]
          : this.logos_icons_white["plans"];
      } else if (this.products && this.hasSubProductsOrServices) {
        return this.logos_icons.conecta_control;
      }
      return Object.keys(this.logos_icons).includes(this.product)
        ? this.logos_icons[this.product]
        : this.logos_icons["plans"];
    },

    getProductLink() {
      return this.product === "conecta_suite"
        ? `${suiteLink}`
        : `${conectaSignLink}`;
    },
  },
};
</script>
<style scoped>
.greyimg {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE */
}
.conecta-ad {
  font-size: 10px !important;
  margin-top: 6px !important;
}
</style>
