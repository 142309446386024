export const TRIAL = "TRIAL";
export const ACTIVE = "ACTIVE";
export const DISABLED = "DISABLED";
export const TRIAL_EXPIRED = "TRIAL_EXPIRED";
export const MAX_TAGS_PER_USER = 30;
export const FREE = "FREE";
export const SUSPENDED = "SUSPENDED";
export const CANCELLED = "CANCELLED";

export const SUBSCRIPTIONS = {
  FLEX: "FLEX",
  QUARTELY: "QUARTELY",
  SEMESTER: "SEMESTER",
  ANNUAL: "ANNUAL",
  BIENNIUM: "BIENNIUM",
  EXTRA: "EXTRA",
  ONCE: "ONCE",
  TRIAL: "TRIAL",
  FREE: "FREE",
};

export const STATUS = {
  TRIAL: TRIAL,
  DISABLED: DISABLED,
  ACTIVE: ACTIVE,
  TRIAL_EXPIRED: TRIAL_EXPIRED,
  FREE: FREE,
  SUSPENDED: SUSPENDED,
  CANCELLED: CANCELLED,
};

export const companyPlan = {
  canceled: null,
  company_key: null,
  discount: null,
  end_date: null,
  end_transaction: null,
  end_trial: null,
  init_billing: null,
  init_date: null,
  init_transaction: null,
  installments: null,
  key: null,
  max_licenses: null,
  next_billing: null,
  notes: null,
  partner_commission: null,
  plan: null,
  plan_key: null,
  price: null,
  status: null,
  subscription_type: null,
};

export const FEATURED_SKU = "SEC-052024";
