<template>
  <PageLoading v-if="isAuthenticating" />
  <AccessForm v-else-if="isAccessFormLayout" />
  <Activation v-else-if="isActivationSteps" />
  <Dashboard v-else-if="isDashboardLayout" />
  <Default v-else />
</template>
<script>
import Activation from "@/layouts/Activation";
import Dashboard from "@/layouts/Dashboard.vue";
import Default from "@/layouts/Default";

import AccessForm from "@/components/AccessForm.vue";
import PageLoading from "./components/PageLoading.vue";

import { mapGetters, mapMutations } from "vuex";
export default {
  name: "App",

  components: {
    Activation,
    AccessForm,
    Dashboard,
    Default,
    PageLoading,
  },
  mounted() {
    if (localStorage.getItem("app_language")) {
      this.$ml.change(localStorage.getItem("app_language"));
      this.setLanguage(localStorage.getItem("app_language"));
    }
  },
  computed: {
    ...mapGetters(["isAuthenticating", "hasFilledForm", "isSimulation"]),

    isAccessFormLayout() {
      return (
        this.$route.meta.requiresAuth &&
        !this.hasFilledForm &&
        !this.isSimulation
      );
    },

    isDashboardLayout() {
      return (
        this.$route.meta.requiresAuth &&
        (this.hasFilledForm || this.isSimulation)
      );
    },

    isActivationSteps() {
      return (
        this.$route.meta.requiresAuth && this.$route.name === "ActivationSteps"
      );
    },
  },
  methods: {
    ...mapMutations(["setLanguage"]),
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
