import { pt_BR } from "@/languages/pt_BR";

const state = () => ({
  sso_info: null,
  showActivationScheduleMeeting: false,
});

const getters = {
  ssoInfo: (state) => state.sso_info,
  showActivationScheduleMeeting: (state) => state.showActivationScheduleMeeting,
};

const actions = {
  activateControl({ getters, commit }, payload) {
    return this._vm.$axios
      .put("/sso/activate", payload)
      .then(({ data }) => {
        commit("setSsoInfo", data);
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "activate_sso_success");
        commit("showOrHideSnackbar", true);
        commit("setSsoStatus", true);
        return Promise.resolve(data);
      })
      .catch(({ response }) => {
        const { data } = response;

        console.error("vuex: activateControl():", data);

        commit("setSnackbarColor", "error");

        if (pt_BR[data.code]) {
          commit("setSnackbarMessage", data.code);
          commit("setSnackbarTimeout", -1);
        } else {
          commit("setSnackbarMessage", "activate_sso_error");
        }

        commit("showOrHideSnackbar", true);

        if (data && data.code == "PLEASE_LOG_IN_AGAIN" && getters.isMicrosoft) {
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/auth/ms`;
        }

        return Promise.reject(response.data);
      });
  },

  async disableControl({ getters, commit }) {
    await this._vm.$axios
      .put("/sso/disable")
      .then(({ data }) => {
        commit("setSsoInfo", data);
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "disable_sso_success");
        commit("showOrHideSnackbar", true);
        commit("setSsoStatus", false);
      })
      .catch((error) => {
        console.error("vuex: disableControl():", error);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", "disable_sso_error");
        commit("showOrHideSnackbar", true);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.code &&
          error.response.data.code == "PLEASE_LOG_IN_AGAIN" &&
          getters.isMicrosoft
        ) {
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/auth/ms`;
        }
      });
  },
};

const mutations = {
  setSsoInfo(state, data) {
    state.sso_info = data;
  },

  setScheduleMeetingDialog(state, value) {
    state.showActivationScheduleMeeting = value;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
