<template>
  <v-alert
    :outlined="outlined"
    :text="text"
    :prominent="prominent"
    :icon="getIcon"
    :elevation="elevation"
    :max-width="max_width"
    :type="type"
    :dense="dense"
    :dark="dark"
    class="ma-0"
  >
    <span
      :class="`pb-0 mb-0 normal-line-height text-wrap break-word ${
        hasAction && $vuetify.breakpoint.mdAndUp ? 'd-flex align-center' : ''
      }
      ${columnLayout ? 'd-flex flex-column' : ''}
      ${
        hasAction && $vuetify.breakpoint.smAndDown
          ? 'd-flex flex-column align-start justify-start'
          : ''
      } ${prominent ? 'mx-4' : 'mx-1'} ${
        $vuetify.theme.dark || dark ? '' : textColor
      } mb-0 pb-0 alert-link ${dense ? 'text-body-2' : ''} text-wrap ${
        getIcon ? 'pr-2' : ''
      }`"
    >
      <slot></slot>
    </span>

    <template #append>
      <slot name="action" />

      <v-btn
        v-if="hasAction"
        width="auto"
        text
        :small="dense"
        :disabled="actionDisabled"
        :color="dark ? 'white' : type"
        outlined
        :dark="dark"
        @click="$emit(action)"
        :class="`caption text-sm-body-2 font-weight-bold ${
          $vuetify.breakpoint.smAndDown ? 'px-0 mt-2' : ''
        } ${alignBtnTextStart ? 'justify-start' : ''}
        ${alignBtnTextEnd ? 'justify-end' : ''}
        `"
      >
        {{ actionText }}
      </v-btn>
    </template>
  </v-alert>
</template>
<script>
export default {
  name: "Alert",
  props: {
    dark: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    text: { type: Boolean, default: true },
    prominent: { type: Boolean, default: false },
    icon: { type: String, default: "" },
    elevation: { type: String, default: "0" },
    max_width: { type: String, default: "" },
    type: { type: String, default: "info" },
    action: { type: String, default: "action" },
    actionText: { type: String, default: "" },
    actionDisabled: { type: Boolean, default: false },
    showAction: { type: Boolean, default: true },
    columnLayout: { type: Boolean, default: false },
    btnWidth: { type: String, default: "20" },
    alignBtnTextStart: { type: Boolean, default: false },
    alignBtnTextEnd: { type: Boolean, default: false },
    textColor: { type: String, default: "grey--text text--darken-4" },
  },
  data() {
    return {
      icons: {
        warning: "mdi-alert" || this.icon,
        error: "mdi-close-octagon" || this.icon,
        info: "mdi-information" || this.icon,
        success: "mdi-check-circle" || this.icon,
      },
    };
  },
  computed: {
    getIcon() {
      if (this.icon !== "") {
        return this.icon;
      }
      return this.icon === "" ? this.icons[this.type] : this.icon === "";
    },
    hasAction() {
      return !!this.action && this.showAction;
    },
  },
};
</script>
