<template>
  <v-snackbar
    v-if="snackbarMessageKey !== ''"
    v-model="showGlobalSnackbar"
    :color="snackbarColor"
    :timeout="snackbarTimeout"
    elevation="2"
    multi-line
    transition="scale-transition"
  >
    <v-row dense align="start">
      <v-col cols="1" v-if="snackbarIcon[snackbarColor]" class="mr-4">
        <v-icon v-text="snackbarIcon[snackbarColor]" size="26" />
      </v-col>
      <v-col>
        <div v-if="snackbarIsAKey" class="text-body-1" style="padding-top: 2px">
          {{ $ml.get(snackbarMessageKey) }}
        </div>
        <div v-else class="text-body-1" style="padding-top: 2px">
          {{ snackbarMessageKey }}
        </div>
      </v-col>
    </v-row>

    <template v-slot:action="{ attrs }">
      <v-btn
        :color="snackbarColor !== 'warning' ? 'white' : 'grey darken-2'"
        text
        v-bind="attrs"
        @click.stop="resetSnackbarInfo"
      >
        {{ $ml.get("close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Snackbar",

  data() {
    return {
      snackbarIcon: {
        success: "mdi-check-circle",
        error: "mdi-close-circle",
        warning: "mdi-alert-circle",
      },
    };
  },

  computed: {
    ...mapGetters([
      "showSnackbar",
      "snackbarMessageKey",
      "snackbarTimeout",
      "snackbarColor",
      "snackbarIsAKey",
    ]),

    showGlobalSnackbar: {
      get() {
        return this.showSnackbar;
      },
      set(new_value) {
        this.showOrHideSnackbar(new_value);
      },
    },
  },

  methods: {
    ...mapMutations(["showOrHideSnackbar"]),

    ...mapActions(["resetSnackbarInfo"]),
  },
};
</script>
