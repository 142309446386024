<template>
  <vue-tel-input-vuetify
    v-model="editPhone"
    :valid-characters-only="true"
    :label="label"
    :placeholder="placeholder"
    :hint="hint"
    :default-country="defaultCountry"
    :preferred-countries="preferredCountries"
    :only-countries="onlyCountries"
    :disabled="disabled"
    :outlined="outlined"
    :rules="rules"
    @input="onInput"
  />
</template>

<script>
export default {
  name: "InternationalPhone",

  props: {
    defaultCountry: { type: String, default: "+55" },
    preferredCountries: { type: Array, default: () => [] },
    onlyCountries: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    label: { type: String, default: "" },
    hint: { type: String, default: "" },
    phone: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    rules: { type: Array, default: () => [] },
  },

  data() {
    return {
      myPhone: "",
    };
  },

  computed: {
    editPhone: {
      get() {
        return this.phone;
      },
      set(newPhone) {
        this.$emit("update", newPhone);
      },
    },
  },

  methods: {
    onInput(formattedNumber, { number, valid, country }) {
      this.editPhone = number.international;
      this.$emit("valid", valid);
      this.$emit("country", country && country.name);
    },
  },
};
</script>
