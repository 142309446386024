<template>
  <v-row dense :justify="justifyBtn">
    <v-btn
      :small="smallBtn"
      :color="color"
      @click="playVideo()"
      depressed
      rounded
      class="rounded"
    >
      <v-icon left> {{ icon }} </v-icon>
      {{ $ml.get("watch_the_video") }}
    </v-btn>

    <v-dialog v-model="play" width="70%">
      <v-card>
        <v-card-title class="text-h5">
          <v-btn icon @click="play = false" border>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          {{ title }}
        </v-card-title>

        <v-card-text>
          <iframe
            v-if="play"
            width="100%"
            height="600"
            :src="`https://www.youtube-nocookie.com/embed/${idYoutubeVideo}?controls=0&amp;start=0&autoplay=1&rel=0&showinfo=0&fs=0&iv_load_policy=3`"
            title="YouTube video player"
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "PlayVideoButton",
  props: {
    color: { type: String, default: "grey lighten-5" },
    icon: { type: String, default: "mdi-play-box" },
    idYoutubeVideo: {
      type: String,
      default: "cr7o8C8yd2s",
    },
    title: { type: String, default: "" },
    justifyBtn: { type: String, default: "center" },
    smallBtn: { type: Boolean, default: false },
  },
  data() {
    return {
      play: false,
    };
  },
  methods: {
    playVideo() {
      this.play = true;
    },
  },
};
</script>

<style></style>
